import dynamic from "next/dynamic";
import { Box } from "@mui/material";
import { ReactNode } from "react";

import Footer from "./Footer";
import Header from "./Header";
import { HeaderBannerListResponseDataItem } from "../../../api/content-client/service";

import tmpHeaderBanner from "./header.webp";

const CookieBanner = dynamic(
  () => import("../../../fe-ui/blocks/CookieBanner/CookieBanner").then((x) => x.CookieBanner),
  {
    ssr: false,
  }
);

type Props = {
  children?: ReactNode;
  renderHeaderContent?: () => ReactNode;
  banners?: HeaderBannerListResponseDataItem[];
  withBackgroundImage?: boolean;
  backgroundImageContent?: () => ReactNode;
};

export default function MainLayout({
  children,
  withBackgroundImage,
  backgroundImageContent,
  renderHeaderContent,
}: Props) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: 1 }}>
      <Header renderHeaderContent={renderHeaderContent} />
      {withBackgroundImage && (
        <Box
          sx={{
            backgroundColor: "#002D62",
            flexShrink: 0,
            minHeight: 600,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundImage: `url(${tmpHeaderBanner.src})`,
          }}
        >
          {backgroundImageContent?.()}
        </Box>
      )}

      <Box
        component={"main"}
        sx={{
          flexGrow: 1,
          pt: !withBackgroundImage ? 12 : 0,
        }}
      >
        {children}
      </Box>

      <Footer />

      <CookieBanner />
    </Box>
  );
}
