const dataTestIds = {
  CONTACTS_SELECTOR: {
    BUTTON: "contacts-selector:button",
    MENU_ITEM: {
      TEL: "contacts-selector:menu-item:tel",
      TG: "contacts-selector:menu-item:tg",
      WA: "contacts-selector:menu-item:wa",
    },
  },
  LANGUAGE_POPOVER: {
    BUTTON: "language-popover:button",
    MENU_ITEM: {
      AZ: "language-popover:menu-item:az",
      RU: "language-popover:menu-item:ru",
      EN: "language-popover:menu-item:en",
    },
  },
  SEARCH: {
    HOTEL_PICKER: {
      BUTTON: "search:hotel-picker:button",
      MENU_ITEM: {
        ALL_HOTELS: "search:hotel-picker:menu-item:all-hotels",
      },
      MENU_ITEM_CONTAINER: "search:hotel-picker:menu-item-container",
    },
    CITY_PICKER: {
      BUTTON: "search:city-picker:button",
      MENU_ITEM: {
        ALL_CITIES: "search:city-picker:menu-item:all-cities",
      },
    },
    DATE_PICKER: {
      BUTTON: "search:date-picker:button",
      CALENDAR: "search:date-picker:calendar",
    },
  },
  PAGES: {
    HOME: {
      UNIT_GROUP_ATTRIBUTES_PHOTO_SLIDER: "pages:home:unit-group-attributes-photo-slider",
      UNIT_GROUP_ATTRIBUTES_SLIDER: "pages:home:unit-group-attributes-slider",
      FUTURE_OFFERS_CONTAINER: "pages:home:future-offers-container",
      SPECIAL_OFFERS_CONTAINER: "pages:home:special-offers-container",
    },
    HOTEL: {
      UNITS_CONTAINER: "pages:hotel:units-container",
    },
    SEARCH: {
      UNITS_CONTAINER: "pages:search:units-container",
    },
  },
  WIDGETS: {
    SIDEBAR_FILTER: {
      PRICE: {
        INPUT: {
          MIN: "widgets:sidebar-filter:price:input:min",
          MAX: "widgets:sidebar-filter:price:input:max",
        },
        SLIDER: "widgets:sidebar-filter:price:slider",
        SLIDER_THUMB: "widgets:sidebar-filter:price:slider-thumb",
      },
      ATTRIBUTES: {
        ATTRIBUTE_TOGGLE_CONTROL: "widgets:sidebar-filter:attributes:attribute-toggle-control",
      },
    },
  },
};

export default dataTestIds;
